import React from 'react';
import { Row, Col, Button } from 'reactstrap';

// Sample data for orders (replace this with actual data if needed)
const OrderReportData = [
    {
        id: 1,
        orderId: 'ORD1234',
        customerName: 'John Doe',
        orderDate: '2024-09-01',
        totalPrice: 1200.00,
        quantity: 3,
        status: 'Shipped',
        color: 'success',
    },
    {
        id: 2,
        orderId: 'ORD1235',
        customerName: 'Jane Smith',
        orderDate: '2024-09-05',
        totalPrice: 800.00,
        quantity: 2,
        status: 'Pending',
        color: 'warning',
    },
    // Add more order data as needed
];

const OrderReport = () => {
    const handleDetailsClick = (orderId) => {
        // Implement the logic to view details for the specific order
        alert(`Details for Order ID: ${orderId}`);
    };

    return (
        <React.Fragment>
            <Row style={{ marginTop: "100px", paddingLeft: "30px", paddingRight: "30px" }}>
                <Col lg={12}>
                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-title mb-4">Order Report</h4>

                            <div className="table-responsive">
                                <table className="table table-centered table-nowrap mb-0">
                                    <thead>
                                        <tr>
                                            <th scope="col" style={{ width: "50px" }}>
                                                <div className="form-check">
                                                    <label className="form-check-label" htmlFor="customCheckall"></label>
                                                </div>
                                            </th>
                                            <th scope="col" style={{ width: "60px" }}></th>
                                            <th scope="col">Order ID</th>
                                            <th scope="col">Customer Name</th>
                                            <th scope="col">Order Date</th>
                                            <th scope="col">Total Price</th>
                                            <th scope="col">Quantity</th>
                                            <th scope="col">Status</th>
                                            <th scope="col">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {OrderReportData.map((order, key) => (
                                            <tr key={key}>
                                                <td>
                                                    <div className="form-check">
                                                        <input type="checkbox" className="form-check-input" id={order.id} />
                                                        <label className="form-check-label" htmlFor={order.id}></label>
                                                    </div>
                                                </td>
                                                <td>
                                                    {/* Placeholder for avatar or customer icon */}
                                                    <div className="avatar-xs">
                                                        <span className="avatar-title rounded-circle bg-primary-subtle text-success">
                                                            {order.customerName.charAt(0)}
                                                        </span>
                                                    </div>
                                                </td>
                                                <td>{order.orderId}</td>
                                                <td>{order.customerName}</td>
                                                <td>{order.orderDate}</td>
                                                <td>$ {order.totalPrice.toFixed(2)}</td>
                                                <td>{order.quantity}</td>
                                                <td>
                                                    <i className={`mdi mdi-checkbox-blank-circle me-1 text-${order.color}`}></i> {order.status}
                                                </td>
                                                <td>
                                                    <Button
                                                        type="button"
                                                        color="primary"
                                                        size="sm"
                                                        className="me-1"
                                                        onClick={() => handleDetailsClick(order.orderId)}
                                                    >
                                                        View Details
                                                    </Button>
                                                    <Button
                                                        type="button"
                                                        color="danger"
                                                        size="sm"
                                                        className="me-1"
                                                    >
                                                        Cancel
                                                    </Button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default OrderReport;
