import React, { useState } from 'react';
import { Row, Col, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

// Sample data for refund requests (replace this with actual data if needed)
const RefundRequestsData = [
    {
        id: 1,
        restaurantName: 'Pasta Place',
        orderId: 'ORD301',
        requestDate: '2024-09-15',
        amountRequested: 30.00,
        refundStatus: 'Requested',
        color: 'warning',
    },
    {
        id: 2,
        restaurantName: 'Pizza Hut',
        orderId: 'ORD302',
        requestDate: '2024-09-16',
        amountRequested: 20.00,
        refundStatus: 'Processed',
        color: 'success',
    },
    // Add more refund requests data as needed
];

const RefundRequests = () => {
    const [dropdownOpen, setDropdownOpen] = useState({});
    const [selectedStatus, setSelectedStatus] = useState({});

    const toggleDropdown = (orderId) => {
        setDropdownOpen(prevState => ({
            ...prevState,
            [orderId]: !prevState[orderId]
        }));
    };

    const handleStatusChange = (orderId, newStatus) => {
        // Implement the logic to update the refund status of the specific order
        alert(`Order ID ${orderId} refund status updated to ${newStatus}`);
        setSelectedStatus(prevState => ({
            ...prevState,
            [orderId]: newStatus
        }));
        // Update state or make API call here
    };

    return (
        <React.Fragment>
            <Row style={{ paddingLeft: "30px", paddingRight: "30px" }}>
                <Col lg={12}>
                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-title mb-4">Refund Requests</h4>

                            <div className="table-responsive">
                                <table className="table table-centered table-nowrap mb-0">
                                    <thead>
                                        <tr>
                                            <th scope="col" style={{ width: "50px" }}>
                                                <div className="form-check">
                                                    <label className="form-check-label" htmlFor="customCheckall"></label>
                                                </div>
                                            </th>
                                            <th scope="col" style={{ width: "60px" }}></th>
                                            <th scope="col">Restaurant</th>
                                            <th scope="col">Order ID</th>
                                            <th scope="col">Request Date</th>
                                            <th scope="col">Amount Requested</th>
                                            <th scope="col">Refund Status</th>
                                            <th scope="col">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {RefundRequestsData.map((request, key) => (
                                            <tr key={key}>
                                                <td>
                                                    <div className="form-check">
                                                        <input type="checkbox" className="form-check-input" id={request.id} />
                                                        <label className="form-check-label" htmlFor={request.id}></label>
                                                    </div>
                                                </td>
                                                <td>
                                                    {/* Placeholder for restaurant icon or logo */}
                                                    <div className="avatar-xs">
                                                        <span className="avatar-title rounded-circle bg-primary-subtle text-success">
                                                            {request.restaurantName.charAt(0)}
                                                        </span>
                                                    </div>
                                                </td>
                                                <td>{request.restaurantName}</td>
                                                <td>{request.orderId}</td>
                                                <td>{request.requestDate}</td>
                                                <td>$ {request.amountRequested.toFixed(2)}</td>
                                                <td>
                                                    <i className={`mdi mdi-checkbox-blank-circle me-1 text-${request.color}`}></i>
                                                    {selectedStatus[request.id] || request.refundStatus}
                                                </td>
                                                <td>
                                                    <Dropdown isOpen={dropdownOpen[request.id] || false} toggle={() => toggleDropdown(request.id)}>
                                                        <DropdownToggle caret color="primary" size="sm">
                                                            {selectedStatus[request.id] || 'Change Status'}
                                                        </DropdownToggle>
                                                        <DropdownMenu style={{ minWidth: '150px', maxHeight: '200px', overflowY: 'auto' }}>
                                                            <DropdownItem onClick={() => handleStatusChange(request.id, 'Requested')}>
                                                                Requested
                                                            </DropdownItem>
                                                            <DropdownItem onClick={() => handleStatusChange(request.id, 'Approved')}>
                                                                Approved
                                                            </DropdownItem>
                                                            <DropdownItem onClick={() => handleStatusChange(request.id, 'Rejected')}>
                                                                Rejected
                                                            </DropdownItem>
                                                            <DropdownItem onClick={() => handleStatusChange(request.id, 'Processed')}>
                                                                Processed
                                                            </DropdownItem>
                                                        </DropdownMenu>
                                                    </Dropdown>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default RefundRequests;
