import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import { Link } from 'react-router-dom';
import logolight from '../../assets/images/logo-light.png';
import logodark from '../../assets/images/logo-dark.png';

const Register = () => {

    const [categories, setCategories] = useState([
        { _id: '1', name: 'Italian' },
        { _id: '2', name: 'Chinese' },
        { _id: '3', name: 'Mexican' },
        { _id: '4', name: 'Indian' },
        { _id: '5', name: 'Japanese' }
    ]);

    document.title = "Register | Upzet - React Admin & Dashboard Template";

    return (
        <React.Fragment>
            <div className="bg-pattern" >
                <div className="bg-overlay"></div>
                <div className="account-pages pt-5">
                    <Container>
                        <Row className="justify-content-center">
                            <Col lg={6} md={8} xl={8}>
                                <Card className='mt-5'>
                                    <CardBody className="p-4">
                                        <div className="text-center">
                                            <Link to="/" className="">
                                                <img src={logodark} alt="" height="24" className="auth-logo logo-dark mx-auto" />
                                                <img src={logolight} alt="" height="24" className="auth-logo logo-light mx-auto" />
                                            </Link>
                                        </div>

                                        <h4 className="font-size-18 text-muted text-center mt-2">Free Register</h4>
                                        <p className="text-muted text-center mb-4">Get your free Upzet account now.</p>
                                        <form className="form-horizontal" action="#">

                                            <Row>
                                                <Col md={6}>
                                                    
                                                   
                                                    <div className="mb-4">
                                                        <label className="form-label" htmlFor="restaurantName">Restaurant Name</label>
                                                        <input type="text" className="form-control" id="restaurantName" placeholder="Enter restaurant name" />
                                                    </div>
                                                    <div className="mb-4">
                                                        <label className="form-label" htmlFor="restaurantPhoneNumber">Restaurant Phone Number</label>
                                                        <input type="text" className="form-control" id="restaurantPhoneNumber" placeholder="Enter restaurant phone number" />
                                                    </div>
                                                    <div className="mb-4">
                                                        <label className="form-label" htmlFor="category">Category</label>
                                                        <select className="form-control" id="category">
                                                            <option value="">Select category</option>
                                                            {categories.map(category => (
                                                                <option key={category._id} value={category._id}>{category.name}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                    <div className="mb-4">
                                                        <label className="form-label" htmlFor="useremail">Email</label>
                                                        <input type="email" className="form-control" id="useremail" placeholder="Enter email" />
                                                    </div>
                                                    <div className="mb-4">
                                                        <label className="form-label" htmlFor="userpassword">Password</label>
                                                        <input type="password" className="form-control" id="userpassword" placeholder="Enter password" defaultValue="123456" />
                                                    </div>


                                                    
                                                </Col>
                                                <Col md={6}>

                                                <div className="mb-4">
                                                        <label className="form-label" htmlFor="accountNumber">Account Number</label>
                                                        <input type="text" className="form-control" id="accountNumber" placeholder="Enter account number" />
                                                    </div>
                                                    <div className="mb-4">
                                                        <label className="form-label" htmlFor="accountTitle">Account Title</label>
                                                        <input type="text" className="form-control" id="accountTitle" placeholder="Enter account title" />
                                                    </div>
                                                    <div className="mb-4">
                                                        <label className="form-label" htmlFor="accountBank">Account Bank</label>
                                                        <input type="text" className="form-control" id="accountBank" placeholder="Enter account bank" />
                                                    </div>
                                                    <div className="mb-4">
                                                        <label className="form-label" htmlFor="contractFile">Contract File</label>
                                                        <input type="text" className="form-control" id="contractFile" placeholder="Enter contract file URL" />
                                                    </div>
                                                   
                                                    <div className="mb-4">
                                                        <label className="form-label" htmlFor="restaurantLogo">Restaurant Logo</label>
                                                        <input type="text" className="form-control" id="restaurantLogo" placeholder="Enter restaurant logo URL" />
                                                    </div>
                                                   
                                                </Col>
                                            </Row>
                                            <div className="form-check">
                                                        <input type="checkbox" className="form-check-input" id="term-conditionCheck" />
                                                        <label className="form-check-label fw-normal" htmlFor="term-conditionCheck">I accept <Link to="#" className="text-primary">Terms and Conditions</Link></label>
                                                    </div>
                                            <div className="d-grid mt-4">
                                                        <a href='/dashboard' className="btn btn-primary waves-effect waves-light" type="submit">Register</a>
                                                    </div>
                                        </form>
                                    </CardBody>
                                </Card>
                                <div className="mt-5 text-center">
                                    <p className="text-white-50">Already have an account ?<Link to="/auth-login" className="fw-medium text-primary"> Login </Link> </p>
                                    <p className="text-white-50">© {new Date().getFullYear()} Upzet. Crafted with <i className="mdi mdi-heart text-danger"></i> by Themesdesign</p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </React.Fragment>
    );
}

export default Register;
