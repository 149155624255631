import React from 'react';
import { Row, Col } from 'reactstrap';

// Sample data for deals (replace this with actual data if needed)
const DealsData = [
    {
        id: 1,
        dealName: 'Deal Alpha',
        date: '2024-09-01',
        dealValue: 1200.00,
        status: 'Completed',
        color: 'success',
    },
    {
        id: 2,
        dealName: 'Deal Beta',
        date: '2024-09-05',
        dealValue: 800.00,
        status: 'Pending',
        color: 'warning',
    },
    // Add more deal data as needed
];

const DealsTable = () => {
    return (
        <React.Fragment>
            <Row style={{ marginTop: "100px", paddingLeft: "30px", paddingRight: "30px" }}>
                <Col lg={12}>
                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-title mb-4">Latest Deals</h4>

                            <div className="table-responsive">
                                <table className="table table-centered table-nowrap mb-0">
                                    <thead>
                                        <tr>
                                            <th scope="col" style={{ width: "50px" }}>
                                                <div className="form-check">
                                                    <label className="form-check-label" htmlFor="customCheckall"></label>
                                                </div>
                                            </th>
                                            <th scope="col" style={{ width: "60px" }}></th>
                                            <th scope="col">Deal Name</th>
                                            <th scope="col">Date</th>
                                            <th scope="col">Value</th>
                                            <th scope="col">Status</th>
                                            <th scope="col">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {DealsData.map((deal, key) => (
                                            <tr key={key}>
                                                <td>
                                                    <div className="form-check">
                                                        <input type="checkbox" className="form-check-input" id={deal.id} />
                                                        <label className="form-check-label" htmlFor={deal.id}></label>
                                                    </div>
                                                </td>
                                                <td>
                                                    {/* Placeholder for avatar or deal icon */}
                                                    <div className="avatar-xs">
                                                        <span className="avatar-title rounded-circle bg-primary-subtle text-success">
                                                            {deal.dealName.charAt(0)}
                                                        </span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <h5 className="font-size-15 mb-0">{deal.dealName}</h5>
                                                </td>
                                                <td>{deal.date}</td>
                                                <td>$ {deal.dealValue.toFixed(2)}</td>
                                                <td>
                                                    <i className={`mdi mdi-checkbox-blank-circle me-1 text-${deal.color}`}></i> {deal.status}
                                                </td>
                                                <td>
                                                    <button type="button" className="btn btn-outline-success btn-sm me-1">Edit</button>
                                                    <button type="button" className="btn btn-outline-danger btn-sm me-1">Cancel</button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default DealsTable;
