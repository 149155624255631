import React from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  Label,
  Input,
  Container,
  FormFeedback,
  Form,
} from "reactstrap";
// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

// Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import FormUpload from "../Forms/FormUpload";

const SubcategoryForm = () => {
  document.title = "Subcategory Form | Upzet - React Admin & Dashboard Template";

  // Form validation for subcategory fields
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      subcategoryName: "",
      description: "",
      image: null,
    },
    validationSchema: Yup.object({
      subcategoryName: Yup.string().required("Subcategory Name is required"),
      description: Yup.string().required("Description is required"),
      image: Yup.mixed().nullable()
        .test("fileSize", "File is too large", value => value && value.size <= 2000000) // 2MB max
        .test("fileFormat", "Unsupported Format", value => value && ['image/jpeg', 'image/png'].includes(value.type)),
    }),
    onSubmit: (values) => {
      console.log("values", values);
    },
  });

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Forms" breadcrumbItem="Subcategory Form" />
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <h4 className="card-title">Subcategory Form</h4>
                  <p className="card-title-desc">
                    Provide details for the subcategory including an image upload if needed.
                  </p>
                  <Form onSubmit={validation.handleSubmit}>
                    <Row>
                      <Col md="6">
                        <FormGroup className="mb-3">
                          <Label htmlFor="subcategoryName">Subcategory Name</Label>
                          <Input
                            name="subcategoryName"
                            placeholder="Enter Subcategory Name"
                            type="text"
                            id="subcategoryName"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.subcategoryName}
                            invalid={validation.touched.subcategoryName && validation.errors.subcategoryName ? true : false}
                          />
                          {validation.touched.subcategoryName && validation.errors.subcategoryName ? (
                            <FormFeedback>{validation.errors.subcategoryName}</FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup className="mb-3">
                          <Label htmlFor="image">Image</Label>
                          <Input
                            name="image"
                            type="file"
                            id="image"
                            onChange={event => {
                              validation.setFieldValue("image", event.currentTarget.files[0]);
                            }}
                            onBlur={validation.handleBlur}
                            invalid={validation.touched.image && validation.errors.image ? true : false}
                          />
                          {validation.touched.image && validation.errors.image ? (
                            <FormFeedback>{validation.errors.image}</FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                     
                    </Row>
                   
                    <Button color="primary" type="submit">
                      Submit
                    </Button>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default SubcategoryForm;
