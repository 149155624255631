import React from 'react';

import { Row, Col } from 'reactstrap';

import { LatestTransationData } from '../../CommonData/Data/index';
import Breadcrumbs from "../../components/Common/Breadcrumb";

const LatestTransation = () => {
    return (
        <React.Fragment>
            <Row style={{ marginTop: "100px",paddingLeft:"30px",paddingRight:"30px"}}>
              <Breadcrumbs title="Product Management" breadcrumbItem="Mangement table" />
                <Col lg={12}>
                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-title mb-4">Products to Manage</h4>

                            <div className="table-responsive">
                                <table className="table table-centered table-nowrap mb-0">

                                    <thead>
                                        <tr>
                                            <th scope="col" style={{ width: "50px" }}>
                                                <div className="form-check">
                                                    <label className="form-check-label" htmlFor="customCheckall"></label>
                                                </div>
                                            </th>
                                          
                                            <th scope="col">Name</th>
                                            <th scope="col">Date</th>
                                           
                                            <th scope="col">Quantity</th>
                                            <th scope="col">Price</th>
                                            <th scope="col">Status</th>
                                            <th scope="col">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {LatestTransationData.map((item, key) => (<tr key={key}>
                                            <td>
                                                <div className="form-check">
                                                    <input type="checkbox" className="form-check-input" id={item.id}
                                                    />
                                                    <label className="form-check-label" htmlFor={item.id}></label>
                                                </div>
                                            </td>
                                          
                                            <td>
                                                <h5 className="font-size-15 mb-0">{item.clientName}</h5>
                                            </td>
                                            <td>{item.date}</td>

                                            <td>{item.quantity}</td>

                                            <td>
                                                 {item.price}$
                                            </td>
                                            <td>
                                            <div className="form-check form-switch form-switch-md mb-3">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="customSwitchsizemd"
                            style={{outline:'none'}}
                          />
                         
                        </div>
                                            </td>
                                            <td>
                                                <button type="button" className="btn btn-outline-success btn-sm me-1">Edit</button>
                                                <button type="button" className="btn btn-outline-danger btn-sm me-1">Cancel</button>
                                            </td>
                                        </tr>))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </React.Fragment>
    )
}

export default LatestTransation;