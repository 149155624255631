import React from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  Label,
  Input,
  Container,
  FormFeedback,
  Form,
} from "reactstrap";
// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

// Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

const DealForm = () => {
  document.title = "Deal Form | Upzet - React Admin & Dashboard Template";

  // Form validation for deal fields
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      dealName: "",
      dealValue: "",
      dealDate: "",
      dealStatus: "",
      dealDescription: "",
    },
    validationSchema: Yup.object({
      dealName: Yup.string().required("Please Enter Deal Name"),
      dealValue: Yup.number().required("Please Enter Deal Value").min(0, "Value must be a positive number"),
      dealDate: Yup.date().required("Please Enter Deal Date"),
      dealStatus: Yup.string().required("Please Select Deal Status"),
      dealDescription: Yup.string().required("Please Enter Deal Description"),
    }),
    onSubmit: (values) => {
      console.log("Deal Form Values:", values);
    },
  });

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Forms" breadcrumbItem="Deal Form" />
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <h4 className="card-title">Deal Form</h4>
                  <p className="card-title-desc">
                    Fill out the details below to create a new deal.
                  </p>
                  <Form onSubmit={validation.handleSubmit}>
                    <Row>
                      <Col md="6">
                        <FormGroup className="mb-3">
                          <Label htmlFor="dealName">Deal Name</Label>
                          <Input
                            name="dealName"
                            placeholder="Enter Deal Name"
                            type="text"
                            id="dealName"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.dealName}
                            invalid={validation.touched.dealName && validation.errors.dealName ? true : false}
                          />
                          {validation.touched.dealName && validation.errors.dealName ? (
                            <FormFeedback>{validation.errors.dealName}</FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup className="mb-3">
                          <Label htmlFor="dealValue">Deal Value</Label>
                          <Input
                            name="dealValue"
                            placeholder="Enter Deal Value"
                            type="number"
                            id="dealValue"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.dealValue}
                            invalid={validation.touched.dealValue && validation.errors.dealValue ? true : false}
                          />
                          {validation.touched.dealValue && validation.errors.dealValue ? (
                            <FormFeedback>{validation.errors.dealValue}</FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <FormGroup className="mb-3">
                          <Label htmlFor="dealDate">Deal Date</Label>
                          <Input
                            name="dealDate"
                            placeholder="Select Deal Date"
                            type="date"
                            id="dealDate"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.dealDate}
                            invalid={validation.touched.dealDate && validation.errors.dealDate ? true : false}
                          />
                          {validation.touched.dealDate && validation.errors.dealDate ? (
                            <FormFeedback>{validation.errors.dealDate}</FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup className="mb-3">
                          <Label htmlFor="dealStatus">Deal Status</Label>
                          <Input
                            name="dealStatus"
                            type="select"
                            id="dealStatus"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.dealStatus}
                            invalid={validation.touched.dealStatus && validation.errors.dealStatus ? true : false}
                          >
                            <option value="">Select Status</option>
                            <option value="pending">Pending</option>
                            <option value="in-progress">In Progress</option>
                            <option value="completed">Completed</option>
                          </Input>
                          {validation.touched.dealStatus && validation.errors.dealStatus ? (
                            <FormFeedback>{validation.errors.dealStatus}</FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="12">
                        <FormGroup className="mb-3">
                          <Label htmlFor="dealDescription">Deal Description</Label>
                          <Input
                            name="dealDescription"
                            placeholder="Enter Deal Description"
                            type="textarea"
                            id="dealDescription"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.dealDescription}
                            invalid={validation.touched.dealDescription && validation.errors.dealDescription ? true : false}
                          />
                          {validation.touched.dealDescription && validation.errors.dealDescription ? (
                            <FormFeedback>{validation.errors.dealDescription}</FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Button color="primary" type="submit">
                      Submit Deal
                    </Button>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default DealForm;
