import React from 'react';
import { Row, Col, Button } from 'reactstrap';

// Sample data for food promotions (replace this with actual data if needed)
const FeaturedPromotionsData = [
    {
        id: 1,
        restaurantName: 'Gourmet Grill',
        dishName: 'Cheeseburger',
        promotionDate: '2024-09-10',
        discount: '20%',
        status: 'Active',
        color: 'success',
    },
    {
        id: 2,
        restaurantName: 'Pasta Palace',
        dishName: 'Spaghetti Carbonara',
        promotionDate: '2024-09-12',
        discount: '15%',
        status: 'Pending',
        color: 'warning',
    },
    // Add more promotion data as needed
];

const FeaturedTable = () => {
    const handleApplyClick = (promotionId) => {
        // Implement the logic to apply for the specific promotion
        alert(`Applied for Promotion ID: ${promotionId}`);
    };

    return (
        <React.Fragment>
            <Row style={{ marginTop: "100px", paddingLeft: "30px", paddingRight: "30px" }}>
                <Col lg={12}>
                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-title mb-4">Featured Promotions</h4>

                            <div className="table-responsive">
                                <table className="table table-centered table-nowrap mb-0">
                                    <thead>
                                        <tr>
                                            <th scope="col" style={{ width: "50px" }}>
                                                <div className="form-check">
                                                    <label className="form-check-label" htmlFor="customCheckall"></label>
                                                </div>
                                            </th>
                                            <th scope="col" style={{ width: "60px" }}></th>
                                            <th scope="col">Restaurant Name</th>
                                            <th scope="col">Dish Name</th>
                                            <th scope="col">Promotion Date</th>
                                            <th scope="col">Discount</th>
                                            <th scope="col">Status</th>
                                            <th scope="col">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {FeaturedPromotionsData.map((promotion, key) => (
                                            <tr key={key}>
                                                <td>
                                                    <div className="form-check">
                                                        <input type="checkbox" className="form-check-input" id={promotion.id} />
                                                        <label className="form-check-label" htmlFor={promotion.id}></label>
                                                    </div>
                                                </td>
                                                <td>
                                                    {/* Placeholder for restaurant icon or logo */}
                                                    <div className="avatar-xs">
                                                        <span className="avatar-title rounded-circle bg-primary-subtle text-success">
                                                            {promotion.restaurantName.charAt(0)}
                                                        </span>
                                                    </div>
                                                </td>
                                                <td>{promotion.restaurantName}</td>
                                                <td>{promotion.dishName}</td>
                                                <td>{promotion.promotionDate}</td>
                                                <td>{promotion.discount}</td>
                                                <td>
                                                    <i className={`mdi mdi-checkbox-blank-circle me-1 text-${promotion.color}`}></i> {promotion.status}
                                                </td>
                                                <td>
                                                    <Button
                                                        type="button"
                                                        color="primary"
                                                        size="sm"
                                                        className="me-1"
                                                        onClick={() => handleApplyClick(promotion.id)}
                                                    >
                                                        Apply
                                                    </Button>
                                                    {/* Add other action buttons if needed */}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default FeaturedTable;
