import React from 'react';
import { Row, Col } from 'reactstrap';

// Dummy data for demonstration. Replace with your actual data.
const ProductAddonsData = [
    { id: 1, name: 'Extra Battery', productId: 'P001', price: 29.99, quantity: 10, totalAmount: 299.90, status: 'Available' },
    { id: 2, name: 'Wireless Charger', productId: 'P002', price: 19.99, quantity: 15, totalAmount: 299.85, status: 'Out of Stock' },
    // Add more product addon data as needed
];

const ProductAddonsTable = () => {
    return (
        <React.Fragment>
            <Row style={{ marginTop: "100px", paddingLeft: "30px", paddingRight: "30px" }}>
                <Col lg={12}>
                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-title mb-4">Product Addons</h4>

                            <div className="table-responsive">
                                <table className="table table-centered table-nowrap mb-0">
                                    <thead>
                                        <tr>
                                            <th scope="col" style={{ width: "50px" }}>
                                                <div className="form-check">
                                                    <label className="form-check-label" htmlFor="customCheckall"></label>
                                                </div>
                                            </th>
                                            <th scope="col" style={{ width: "60px" }}></th>
                                            <th scope="col">Addon Name</th>
                                            <th scope="col">Product ID</th>
                                            <th scope="col">Price</th>
                                            <th scope="col">Quantity</th>
                                            <th scope="col">Total Amount</th>
                                            <th scope="col">Status</th>
                                            <th scope="col">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {ProductAddonsData.map((item, key) => (
                                            <tr key={key}>
                                                <td>
                                                    <div className="form-check">
                                                        <input type="checkbox" className="form-check-input" id={`checkbox-${item.id}`} />
                                                        <label className="form-check-label" htmlFor={`checkbox-${item.id}`}></label>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="avatar-xs">
                                                        <span className="avatar-title rounded-circle bg-primary-subtle text-success">
                                                            {item.name.charAt(0)}
                                                        </span>
                                                    </div>
                                                </td>
                                                <td>
                                                    <h5 className="font-size-15 mb-0">{item.name}</h5>
                                                </td>
                                                <td>{item.productId}</td>
                                                <td>$ {item.price.toFixed(2)}</td>
                                                <td>{item.quantity}</td>
                                                <td>$ {item.totalAmount.toFixed(2)}</td>
                                                <td>
                                                    <i className={"mdi mdi-checkbox-blank-circle me-1 text-" + (item.status === 'Available' ? 'success' : 'danger')}></i> {item.status}
                                                </td>
                                                <td>
                                                    <button type="button" className="btn btn-outline-success btn-sm me-1">Edit</button>
                                                    <button type="button" className="btn btn-outline-danger btn-sm me-1">Delete</button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </React.Fragment>
    );
}

export default ProductAddonsTable;
