import React from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  Label,
  Input,
  Container,
  FormFeedback,
  Form,
  InputGroup,
  InputGroupText
} from "reactstrap";
// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

// Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

const CouponForm = () => {
  document.title = "Coupon Form | Upzet - React Admin & Dashboard Template";

  // Form validation for coupon fields
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      food: "",
      usageLimit: "",
      duration: "",
      couponCode: "",
      discountPercentage: "",
      cost: "",
    },
    validationSchema: Yup.object({
      food: Yup.string().required("Please select a food item"),
      usageLimit: Yup.number().required("Usage limit is required").positive().integer(),
      duration: Yup.string().required("Duration is required"),
      couponCode: Yup.string().required("Coupon code is required"),
      discountPercentage: Yup.number().required("Discount percentage is required").min(0).max(100),
      cost: Yup.number().required("Cost is required").positive(),
    }),
    onSubmit: (values) => {
      console.log("values", values);
    },
  });

  // Example food options, replace with dynamic options as needed
  const foodOptions = [
    { value: "", label: "Select Food" },
    { value: "pizza", label: "Pizza" },
    { value: "burger", label: "Burger" },
    { value: "pasta", label: "Pasta" },
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Forms" breadcrumbItem="Coupon Form" />
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <h4 className="card-title">Coupon Form</h4>
                  <Form onSubmit={validation.handleSubmit}>
                    <Row>
                      <Col md="6">
                        <FormGroup className="mb-3">
                          <Label htmlFor="food">Food Item</Label>
                          <Input
                            type="select"
                            name="food"
                            id="food"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.food}
                            invalid={validation.touched.food && validation.errors.food ? true : false}
                          >
                            {foodOptions.map(option => (
                              <option key={option.value} value={option.value}>{option.label}</option>
                            ))}
                          </Input>
                          {validation.touched.food && validation.errors.food ? (
                            <FormFeedback>{validation.errors.food}</FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup className="mb-3">
                          <Label htmlFor="couponCode">Coupon Code</Label>
                          <Input
                            name="couponCode"
                            placeholder="Enter Coupon Code"
                            type="text"
                            id="couponCode"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.couponCode}
                            invalid={validation.touched.couponCode && validation.errors.couponCode ? true : false}
                          />
                          {validation.touched.couponCode && validation.errors.couponCode ? (
                            <FormFeedback>{validation.errors.couponCode}</FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <FormGroup className="mb-3">
                          <Label htmlFor="usageLimit">Usage Limit</Label>
                          <Input
                            name="usageLimit"
                            placeholder="Enter Usage Limit"
                            type="number"
                            id="usageLimit"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.usageLimit}
                            invalid={validation.touched.usageLimit && validation.errors.usageLimit ? true : false}
                          />
                          {validation.touched.usageLimit && validation.errors.usageLimit ? (
                            <FormFeedback>{validation.errors.usageLimit}</FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup className="mb-3">
                          <Label htmlFor="duration">Duration</Label>
                          <Input
                            name="duration"
                            placeholder="Enter Duration"
                            type="text"
                            id="duration"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.duration}
                            invalid={validation.touched.duration && validation.errors.duration ? true : false}
                          />
                          {validation.touched.duration && validation.errors.duration ? (
                            <FormFeedback>{validation.errors.duration}</FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <FormGroup className="mb-3">
                          <Label htmlFor="discountPercentage">Discount Percentage</Label>
                          <InputGroup>
                            <Input
                              name="discountPercentage"
                              placeholder="Enter Discount Percentage"
                              type="number"
                              id="discountPercentage"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.discountPercentage}
                              invalid={validation.touched.discountPercentage && validation.errors.discountPercentage ? true : false}
                            />
                            <InputGroupText>%</InputGroupText>
                          </InputGroup>
                          {validation.touched.discountPercentage && validation.errors.discountPercentage ? (
                            <FormFeedback>{validation.errors.discountPercentage}</FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup className="mb-3">
                          <Label htmlFor="cost">Cost</Label>
                          <InputGroup>
                            <Input
                              name="cost"
                              placeholder="Enter Cost"
                              type="number"
                              id="cost"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.cost}
                              invalid={validation.touched.cost && validation.errors.cost ? true : false}
                            />
                            <InputGroupText>$</InputGroupText>
                          </InputGroup>
                          {validation.touched.cost && validation.errors.cost ? (
                            <FormFeedback>{validation.errors.cost}</FormFeedback>
                          ) : null}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Button color="primary" type="submit">
                      Submit
                    </Button>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CouponForm;
